import { ORGBRAIN_VERSION } from '../orgbrain_version';

export const sharedenvironment = {
  ORGBRAIN_VERSION,
  sessionDataLabel: 'orgbrainSession',
  debug: false,
  bisnodeServer: `https://org-data-server.orgbrain.ai/bisnode`, // 'http://127.0.0.1:8081',
  homeApp: 'home',
  jitsiMeetUrl: 'meet.orgbrain.ai',
  orgbrain: true,
  HTTP_TIMEOUT: 51234,
  taxDataUrl: 'https://taxdata-server.orgbrain.ai/tax',

  helpUrl: 'https://support.orgbrain.ai',
  bankid2FA: false,
  adminPath: '/admin',
  shareholdersPath: '/shareholders',
  filesPath: '/files',
  versionUpdateCheckPeriod: 60000,
  SERVER_VERSION_CHECK: true,
  //  check for activity bebouncing to avoid to many notifications.
  activityCheckDebounce: 10 * 1000,
  ip2comURL: 'https://api.ip2loc.com/0aBK3WbrqmVvrpLrl4WOptLVyGBIs1ej/',
  GET_RID_OF_ALL_THE_RUBISH_IN_THE_SERVER_LOG_THAT_MAKES_DEBUGING_IMPOSSIBLE: false,
  defaultLang: '',
  nudgeIfAboutToExpireTime: 10 * 60 * 1000,
  loguncaughtErrors: true,
  EMAIL_QUERY_SECRET: 'Ploutonion',
  ENABLE_STRESS_SERVICE: false,
  showbankIdLogon: true,
};
