export const texts = {
  Transactions: {
    default: {
      en: 'Transactions',
      nb: 'Transaksjoner',
      sv: 'Transaktioner',
    },
  },
  'Beneficial owners': {
    default: {
      en: 'Beneficial owners',
      nb: 'Reelle eiere',
      sv: 'Verkliga rättighetshavare',
    },
  },
  'New transaction': {
    default: {
      en: 'New transaction',
      nb: 'Ny transaksjon',
      sv: 'Ny transaktion',
    },
  },
  DataRoom: {
    default: {
      en: 'Common files for the board',
      nb: 'Felles filer for styret',
      sv: 'Gemensamma filer för styrelsen',
    },
    SUBGR: {
      en: 'Common files',
      nb: 'Felles filer',
      sv: 'Gemensamma filer',
    },
  },
  Owners: {
    default: {
      en: 'Owners',
      nb: 'Eiere',
      sv: 'Ägare',
    },
    fliAlike: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
    BRL: {
      en: 'Unitholders',
      nb: 'Andelseiere',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owners',
      nb: 'Seksjonseiere',
      sv: 'Avsnittsägare',
    },
  },
  Owner: {
    default: {
      en: 'Owner',
      nb: 'Eier',
      sv: 'Ägar',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unitholder',
      nb: 'Andelseier',
      sv: 'Andelsägar',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Avsnittsägar',
    },
  },
  'the owner': {
    default: {
      en: 'the owner',
      nb: 'eieren',
      sv: 'ägaren',
    },
    fliAlike: {
      en: 'the member',
      nb: 'medlemet',
      sv: 'medlemet',
    },
    BRL: {
      en: 'the unitholder',
      nb: 'andelseieren',
      sv: 'andelsägaren',
    },
    ESEK: {
      en: 'the section owner',
      nb: 'seksjonseieren',
      sv: 'avsnittsägaren',
    },
  },
  'the owners': {
    default: {
      en: 'the owners',
      nb: 'eierene',
      sv: 'ägarene',
    },
    fliAlike: {
      en: 'the members',
      nb: 'medlemmene',
      sv: 'medlemmene',
    },
    BRL: {
      en: 'the unitholders',
      nb: 'andelseierene',
      sv: 'andelsägarene',
    },
    ESEK: {
      en: 'the section owners',
      nb: 'seksjonseierene',
      sv: 'avsnittsägarene',
    },
  },
  'an owner': {
    default: {
      en: 'an owner',
      nb: 'en eier',
      sv: 'en ägar',
    },
    fliAlike: {
      en: 'a member',
      nb: 'et medlem',
      sv: 'et medlem',
    },
    BRL: {
      en: 'a unitholder',
      nb: 'en andelseier',
      sv: 'en andelsägar',
    },
    ESEK: {
      en: 'a section owner',
      nb: 'en seksjonseier',
      sv: 'en avsnittsägar',
    },
  },
  'Share numbers': {
    default: {
      en: 'Share numbers',
      nb: 'Aksjenummer',
    },
    fliAlike: {
      en: 'Membership number',
      nb: 'Medlemsnummer',
    },
    BRL: {
      en: 'Share number',
      nb: 'Andelsnummer',
    },
    ESEK: {
      en: 'Section number',
      nb: 'Seksjonsnummer',
    },
  },
  Shareclass: {
    default: {
      en: 'Shareclass',
      nb: 'Aksjeklasse',
    },
    fliAlike: {
      en: 'Member category',
      nb: 'Medlemskategori',
    },
    BRL: {
      en: 'Share category',
      nb: 'Andelskategori',
    },
    ESEK: {
      en: 'Section category',
      nb: 'Seksjonskategori',
    },
  },
  'Total number of owners': {
    default: {
      en: 'Total number of owners',
      nb: 'Totalt antall eiere',
      sv: 'Totalt antal ägare',
    },
    fliAlike: {
      en: 'Total number of members',
      nb: 'Totalt antall medlemmer',
      sv: 'Totalt antal medlemmar',
    },
    BRL: {
      en: 'Total number of unitholders',
      nb: 'Totalt antall andelseiere',
      sv: 'Totalt antal andelsägare',
    },
    ESEK: {
      en: 'Total number of section owners',
      nb: 'Totalt antall seksjonseiere',
      sv: 'Totalt antal sektionsägare',
    },
  },
  'Select owner': {
    default: {
      en: 'Select owner',
      nb: 'Velg eier',
      sv: 'Välj ägare',
    },
    fliAlike: {
      en: 'Select member',
      nb: 'Velg medlem',
      sv: 'Välj medlem',
    },
  },
  // ROLES orgbrain-db/libs/roles/src/lib/roles.ts BEGIN
  CEO: {
    default: {
      en: 'CEO',
      nb: 'Daglig leder',
      sv: 'CEO',
    },
  },
  CHAIR: {
    default: {
      en: 'Chair',
      nb: 'Styrets leder',
      sv: 'Ordförande',
    },
    SUBGR: {
      en: 'Chair',
      nb: 'Leder',
      sv: 'Ordförande',
    },
  },
  BOARDMEMBER: {
    default: {
      en: 'Board member',
      nb: 'Styremedlem',
      sv: 'Styrelsemedlem',
    },
    SUBGR: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
  },
  SHAREHOLDER: {
    default: {
      en: 'Shareholder',
      nb: 'Aksjeeier',
      sv: 'Andelsägare',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unitholder',
      nb: 'Andelseier',
      sv: 'Enhetsägare',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Sektionsägare',
    },
  },
  SHAREHOLDER_PROXY: {
    default: {
      en: 'Shareholder proxy',
      nb: 'Aksjeeierfullmektig',
      sv: 'Andelsägarens proxy',
    },
    fliAlike: {
      en: 'Proxy',
      nb: 'Fullmektig',
      sv: 'Fullmakt',
    },
    BRL: {
      en: 'Unitholder proxy',
      nb: 'Andelseierfullmektig',
      sv: 'Enhetsägarens fullmakt',
    },
    ESEK: {
      en: 'Section owner proxy',
      nb: 'Seksjonseierfullmektig',
      sv: 'Sektionsägarens fullmakt',
    },
  },
  ADMIN: {
    default: {
      en: 'Administrator',
      nb: 'Administrator',
      sv: 'Administratör',
    },
  },
  USER_ADMIN: {
    default: {
      en: 'User administrator',
      nb: 'Bruker-administrator',
      sv: 'Användaradministratör',
    },
  },
  DEPUTYCHAIR: {
    default: {
      en: 'Deputy chair',
      nb: 'Styrets nestleder',
      sv: 'Vice ordförande',
    },
    SUBGR: {
      en: 'Deputy chair',
      nb: 'Nestleder',
      sv: 'Vice ordförande',
    },
  },
  DEPUTYMEMBER: {
    default: {
      en: 'Deputy member',
      nb: 'Varamedlem',
      sv: 'Biträdande ledamot',
    },
  },
  BOARDSECRETARY: {
    default: {
      en: 'Board secretary',
      nb: 'Styresekretær',
      sv: 'Styrelsens sekreterare',
    },
  },
  CONTACTPERSON: {
    default: {
      en: 'Contact person',
      nb: 'Kontaktperson',
      sv: 'Kontaktperson',
    },
  },
  OBSERVER: {
    default: {
      en: 'Observer',
      nb: 'Observatør',
      sv: 'Observatör',
    },
  },
  AUDITOR: {
    default: {
      en: 'Auditor',
      nb: 'Revisor',
      sv: 'Revisor',
    },
  },
  BUYER: {
    default: {
      en: 'Due diligence user',
      nb: 'Due diligence-bruker',
      sv: 'Due diligence user',
    },
  },
  SCHOOL: {
    default: {
      en: 'Student',
      nb: 'Student',
      sv: 'Studerande',
    },
  },
  // ROLES orgbrain-db/libs/roles/src/lib/roles.ts END
  Shareholder: {
    default: {
      en: 'Shareholder',
      nb: 'Aksjonær',
      sv: 'Aktieägare',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unitholder',
      nb: 'Andelseier',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Avdelningsägare',
    },
  },
  Shareholders: {
    default: {
      en: 'Shareholders',
      nb: 'Aksjeeiere',
      sv: 'Aktieägare',
    },
    fliAlike: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
    BRL: {
      en: 'Unitholders',
      nb: 'Andelseiere',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owners',
      nb: 'Seksjonseiere',
      sv: 'Avsnittsägare',
    },
  },
  'No shareholders to invite': {
    default: {
      en: 'No shareholders to invite',
      nb: 'Ingen aksjonærer å invitere',
      sv: 'Inga aktieägare att bjuda in',
    },
    fliAlike: {
      en: 'No members to invite',
      nb: 'Ingen medlemmer å invitere',
      sv: 'Inga medlemmar att bjuda in',
    },
  },
  'Invite shareholders': {
    default: {
      en: 'Invite shareholders',
      nb: 'Inviter eiere',
      sv: 'Bjud in aktieägare',
    },
    fliAlike: {
      en: 'Invite members',
      nb: 'Inviter medlemmer',
      sv: 'Bjud in medlemmar',
    },
    BRL: {
      en: 'Invite unitholders',
      nb: 'Inviter andelseiere',
      sv: 'Bjud in andelsägare',
    },
    ESEK: {
      en: 'Invite section owners',
      nb: 'Inviter seksjonseiere',
      sv: 'Bjud in sektionens ägare',
    },
  },
  AddNewShareRecord: {
    default: {
      en: 'New share record',
      nb: 'Registrer aksjepost',
      sv: 'Ny aktierekord',
    },
    fliAlike: {
      en: 'New membership',
      nb: 'Nytt medlemskap',
      sv: 'Nytt medlemskap',
    },
    BRL: {
      en: 'New unit',
      nb: 'Registrer ny andel',
      sv: 'Ny enhet',
    },
    ESEK: {
      en: 'New section',
      nb: 'Ny seksjon',
      sv: 'Nytt avsnitt',
    },
  },
  Sharerecords: {
    default: {
      en: 'Share records',
      nb: 'Aksjeposter',
      sv: 'Aktierekorder',
    },
    fliAlike: {
      en: 'Memberships',
      nb: 'Medlemskaper',
      sv: 'Medlemskaper',
    },
    BRL: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Enheter',
    },
    ESEK: {
      en: 'Sections',
      nb: 'Seksjoner',
      sv: 'Avsnitter',
    },
  },
  'Create owner': {
    default: {
      en: 'Create owner',
      nb: 'Opprette eier',
      sv: 'Skapa ägare',
    },
    fliAlike: {
      en: 'Create member',
      nb: 'Opprette medlem',
      sv: 'Skapa medlem',
    },
  },
  'Share classes': {
    default: {
      en: 'Share classes',
      nb: 'Aksjeklasser',
      sv: 'Dela klasser',
    },
    fliAlike: {
      en: 'Member types',
      nb: 'Medlemskategorier',
      sv: 'Medlemstyper',
    },
    BRL: {
      en: 'Unit categories',
      nb: 'Andelskategorier',
      sv: 'Enhetskategorier',
    },
    ESEK: {
      en: 'Section owner categories',
      nb: 'Seksjonseierkategorier',
      sv: 'Kategori ägarkategorier',
    },
  },
  'Share class': {
    default: {
      en: 'Share class',
      nb: 'Aksjeklasse',
      sv: 'Andelsklass',
    },
    fliAlike: {
      en: 'Member type',
      nb: 'Medlemskategori',
      sv: 'Medlemstyp',
    },
    BRL: {
      en: 'Unit category',
      nb: 'Andelskategori',
      sv: 'Enhetskategori',
    },
    ESEK: {
      en: 'Section owner category',
      nb: 'Seksjonseierkategori',
      sv: 'Kategori ägare kategori',
    },
  },
  'Transfer of shares': {
    default: {
      en: 'Transfer of shares',
      nb: 'Overføring av aksjer',
    },
  },
  'Share register': {
    default: {
      en: 'Share register',
      nb: 'Aksjeeierbok',
      sv: 'Aktieregister',
    },
    fliAlike: {
      en: 'Membership register',
      nb: 'Medlemsregister',
      sv: 'Medlemskapsregister',
    },
    BRL: {
      en: 'Unit register',
      nb: 'Andelseierregister',
      sv: 'Enhetsregister',
    },
    ESEK: {
      en: 'Section owner register',
      nb: 'Seksjonseierregister',
      sv: 'Avsnittsregistret',
    },
  },
  'Notes/Share no.': {
    default: {
      en: 'Notes/Share no.',
      nb: 'Merknader/Aksjenummer',
      sv: 'Anteckningar / Aktienr.',
    },
    fliAlike: {
      en: 'Member no.',
      nb: 'Medlemsnummer',
      sv: 'Medlem nr.',
    },
    BRL: {
      en: 'Unit number',
      nb: 'Andelsnummer',
      sv: 'Enhetsnummer',
    },
    ESEK: {
      en: 'Section number',
      nb: 'Seksjonsnummer',
      sv: 'Avsnittsnummer',
    },
  },
  'Share no.': {
    default: {
      en: 'Share no.',
      nb: 'Aksjenummer',
      sv: 'Aktienr.',
    },
    fliAlike: {
      en: 'Member no.',
      nb: 'Medlemsnummer',
      sv: 'Medlem nr.',
    },
    BRL: {
      en: 'Unit number',
      nb: 'Andelsnummer',
      sv: 'Enhetsnummer',
    },
    ESEK: {
      en: 'Section number',
      nb: 'Seksjonsnummer',
      sv: 'Avsnittsnummer',
    },
  },
  'Number of shares': {
    default: {
      en: 'Number of shares',
      nb: 'Antall aksjer',
      sv: 'Antal delningar',
    },
    fliAlike: {
      en: 'Number of votes',
      nb: 'Antall stemmer',
      sv: 'Röstetal',
    },
    BRL: {
      en: 'Number of votes',
      nb: 'Antall stemmer',
      sv: 'Röstetal',
    },
    ESEK: {
      en: 'Number of votes',
      nb: 'Antall stemmer',
      sv: 'Röstetal',
    },
  },
  Shares: {
    default: {
      en: 'Shares',
      nb: 'Aksjer',
      sv: 'Aktier',
    },
    fliAlike: {
      en: 'Membership',
      nb: 'Medlemskap',
      sv: 'Medlemskap',
    },
    BRL: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Enheter',
    },
    ESEK: {
      en: 'Sections',
      nb: 'Seksjoner',
      sv: 'Avsnitt',
    },
  },
  SharesVotes: {
    default: {
      en: 'Shares',
      nb: 'Aksjer',
      sv: 'Aktier',
    },
    fliAlike: {
      en: 'Votes',
      nb: 'Stemmer',
      sv: 'Röster',
    },
  },
  Votes: {
    default: {
      en: 'Votes',
      nb: 'Stemmer',
      sv: 'Röster',
    },
  },
  'Nobody got elected': {
    default: {
      en: 'Nobody got elected',
      nb: 'Ingen ble valgt',
      sv: 'Ingen blev vald',
    },
  },
  'Total in company': {
    default: {
      en: 'Total in company',
      nb: 'Totalt i selskapet',
      sv: 'Totalt i företaget',
    },
    fliAlike: {
      en: 'Total in organization',
      nb: 'Totalt i organisasjon',
      sv: 'Totalt i organisationen',
    },
  },
  'of attending SC': {
    default: {
      en: 'of attending SC',
      nb: 'av fremmøtte AK',
      sv: 'för att delta i SC',
    },
    fliAlike: {
      en: 'of attending',
      nb: 'av fremmøtte',
      sv: 'av att delta',
    },
  },
  'of represented votes': {
    default: {
      en: 'of represented votes',
      nb: 'av representerte stemmer',
      sv: 'av representerade röster',
    },
  },
  'The general meeting ended.': {
    default: {
      en: 'The general meeting ended.',
      nb: 'Generalforsamlingen ble avsluttet.',
      sv: 'Stämman avslutades.',
    },
    fliAlike: {
      en: 'The meeting ended.',
      nb: 'Møtet ble avsluttet.',
      sv: 'Mötet avslutades.',
    },
  },
  'List of attending shareholders': {
    default: {
      en: 'List of attending shareholders',
      nb: 'Liste over fremmøtte aksjeeiere',
      sv: 'Lista över deltagande aktieägare',
    },
    fliAlike: {
      en: 'List of attending members',
      nb: 'Liste over fremmøtte medlemmer',
      sv: 'Lista över deltagande medlemmar',
    },
  },
  'Save owner': {
    default: {
      en: 'Save owner',
      nb: 'Lagre eier',
      sv: 'Spara ägaren',
    },
    fliAlike: {
      en: 'Save member',
      nb: 'Lagre bruker',
      sv: 'Spara medlem',
    },
  },
  'Add new owner': {
    default: {
      en: 'Add new owner',
      nb: 'Legg til ny aksjeeier',
      sv: 'Lägg till ny ägare',
    },
    fliAlike: {
      en: 'Add new member',
      nb: 'Legg til nytt medlem',
      sv: 'Lägg till ny medlem',
    },
  },
  'Share capital': {
    default: {
      en: 'Share capital',
      nb: 'Aksjekapital',
      sv: 'Aktiekapital',
    },
  },
  'Number of participants': {
    default: {
      en: 'Total representatives entitled to vote',
      nb: 'Totalt representerte stemmeberettigede',
      sv: 'Totalt antal rösträtt',
    },
    fliAlike: {
      en: 'Number of participants',
      nb: 'Antall deltakere',
      sv: 'Antal deltagare',
    },
  },
  'of total votes': {
    default: {
      en: 'of total votes',
      nb: 'av totale stemmer',
      sv: 'av de totala rösterna',
    },
  },
  'of total capital': {
    default: {
      en: 'of total capital',
      nb: 'av total kapital',
      sv: 'av det totala kapitalet',
    },
  },
  'Invite board': {
    default: {
      en: 'Invite board',
      nb: 'Inviter styret',
      sv: 'Bjud in styrelsen',
    },
    SUBGR: {
      en: 'Invite to a meeting',
      nb: 'Inviter til møte',
      sv: 'Bjud in till ett möte',
    },
  },
  'Select participants from list': {
    default: {
      en: 'Select participants from list',
      nb: 'Velg deltakere fra liste',
    },
  },
  'Create agenda as PDF': {
    default: {
      en: 'Create agenda as PDF',
      nb: 'Lag agenda som PDF',
    },
  },
  'Create board book as PDF': {
    default: {
      en: 'Create board book as PDF',
      nb: ' Lag styrebok som PDF',
    },
  },
  'A share class is necessary for creating a share register': {
    default: {
      en: 'A share class is necessary for creating a share register',
      nb: 'En aksjeklasse er nødvendig for å lage et aksjeregister',
      sv: 'En andelsklass är nödvändig för att skapa ett aktieregister',
    },
    fliAlike: {
      en: 'A membership category is necessary for creating a membership register',
      nb: 'En medlemskategori er nødvendig for å lage et medlemsregister',
      sv: 'En medlemskategori är nödvändig för att skapa ett medlemsregister',
    },
  },
  'Board members': {
    default: {
      en: 'Board members',
      nb: 'Styremedlemmer',
      sv: 'Styrelsemedlemmar',
    },
    SUBGR: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
  },
  'Ordinary shares': {
    default: {
      en: 'Ordinary shares',
      nb: 'Ordinære aksjer',
      sv: 'Vanliga aktier',
    },
    fliAlike: {
      en: 'Ordinary member',
      nb: 'Ordinært medlem',
      sv: 'Vanlig medlem',
    },
    BRL: {
      en: 'Primary unit',
      nb: 'Primærandel',
      sv: 'Primär enhet',
    },
    ESEK: {
      en: 'Primary section',
      nb: 'Primærseksjon',
      sv: 'Primär sektion',
    },
  },
  companyInfo: {
    default: {
      en: 'Share data',
      nb: 'Aksjedata',
      sv: 'Aktiedata',
    },
    BRL: {
      en: 'Unit data',
      nb: 'Andelsdata',
      sv: 'Bostadsföreningens info',
    },
    ESEK: {
      en: 'Section data',
      nb: 'Seksjonsdata',
      sv: 'Info om delägande',
    },
    fliAlike: {
      en: 'Member data',
      nb: 'Medlemsdata',
      sv: 'Medlemsdata',
    },
  },
  shareNotesText: {
    default: {
      en: 'Claims / notes',
      nb: 'Pant / merknader',
      sv: 'Anspråk / anteckningar',
    },
    alternative: {
      en: 'Notes',
      nb: 'Merknader',
      sv: 'Anteckningar',
    },
  },
  'Company name': {
    default: {
      en: 'Company name',
      nb: 'Selskapsnavn',
      sv: 'Företagsnamn',
    },
    fliAlike: {
      en: 'Member name',
      nb: 'Medlemsnavn',
      sv: 'Medlemsnamn',
    },
    BRL: {
      en: 'Company name',
      nb: 'Foretaksnavn',
      sv: 'Företagsnamn',
    },
    ESEK: {
      en: 'Company name',
      nb: 'Foretaksnavn',
      sv: 'Företagsnamn',
    },
  },
  no_invited_shareholders: {
    default: {
      en: 'No invited shareholders yet',
      nb: 'Enda ingen inviterte aksjeeiere',
      sv: 'Inga inbjudna aktieägare ännu',
    },
    fliAlike: {
      en: 'No invited members yet',
      nb: 'Enda ingen inviterte medlemmer',
      sv: 'Inga inbjudna medlemmar än',
    },
    BRL: {
      en: 'No invited unitholders yet',
      nb: 'Enda ingen inviterte andelseiere',
      sv: 'Inga inbjudna andelsägare ännu',
    },
    ESEK: {
      en: 'No invited section owners yet',
      nb: 'Enda ingen inviterte seksjonseiere',
      sv: 'Inga inbjudna sektionsägare ännu',
    },
  },
  BoardGroupBoard: {
    default: {
      en: 'Board',
      nb: 'Styret',
      sv: 'Styrelsen',
    },
  },
  BoardGroupCustom: {
    default: {
      en: 'Custom',
      nb: 'Tilpasset',
      sv: 'Beställnings',
    },
  },
  ORDINARY_MAJORITY: {
    default: {
      en: 'Majority of votes cast (Norw. Companies Act § 5-17)',
      nb: 'Flertall av avgitte stemmer (aksjeloven § 5-17)',
      sv: 'Majoriteten av de avgivna rösterna (norska aktiebolagslagen § 5-17)',
    },
    alternative: {
      en: 'Majority requirements',
      nb: 'Flertallskrav',
      sv: 'Majoritetskrav',
    },
  },
  STATUTORY_CHANGE: {
    default: {
      en: 'Minimum 2/3 of votes cast and 2/3 of shares represented (§ 5-18)',
      nb: 'Minst 2/3 av aksjer representert og 2/3 av avgitte stemmer (§ 5-18)',
      sv: 'Minst 2/3 av de avgivna rösterna och 2/3 av de representerade aktierna (§ 5-18)',
    },
    alternative: {
      en: 'At least 2/3 of those represented and of the votes cast',
      nb: 'Minst 2/3 av av de som er representert og avgitte stemmene',
      sv: 'Minst 2/3 av de representerade och av de avgivna rösterna',
    },
  },
  QUALIFIED_MAJORITY: {
    default: {
      en: 'Minimum 9/10 of shares represented and 2/3 of votes cast (§ 5-19)',
      nb: 'Minst 9/10 av aksjer representert og 2/3 av avgitte stemmer (§ 5-19)',
      sv: 'Minst 9/10 representerade aktier och 2/3 av de avgivna rösterna (§ 5-19)',
    },
    alternative: {
      en: 'At least 9/10 of those represented and 2/3 of the votes cast',
      nb: 'Minst 9/10 av de som er representert og 2/3 av avgitte stemmer',
      sv: 'Minst 9/10 av de representerade och 2/3 av de avgivna rösterna',
    },
  },
  UNANIMITY: {
    default: {
      en: 'Unanimity, all shareholders (§ 5-20)',
      nb: 'Enstemmighet, alle aksjonærer (§ 5-20)',
      sv: 'Enighet, alla aktieägare (§ 5-20)',
    },
    alternative: {
      en: 'Unanimity from all those entitled to vote',
      nb: 'Enstemmighet fra samtlige stemmeberettigede',
      sv: 'Enighet från alla röstberättigade',
    },
  },
  INVESTIGATION_PROPOSITION: {
    default: {
      en: 'Minimum 1/10 of shares represented (§ 5-25)',
      nb: 'Minst 1/10 av aksjer representert (§ 5-25)',
      sv: 'Minst 1/10 aktier representerade (§ 5-25)',
    },
    alternative: {
      en: 'At least 1/10 of the votes represented',
      nb: 'Minst 1/10 av stemmer representert',
      sv: 'Minst 1/10 av de representerade rösterna',
    },
  },
  LongTextProxyLegal: {
    default: {
      en:
        'I/we authorize the person above to vote for my/our shares. If necessary, the proxy may transfer the proxy to a third party. By filling in the information below, I submit my electronic signature on this authorization.',
      nb:
        'Jeg/vi gir med dette fullmakt til at ovennevnte kan stemme for mine/​våre aksjer. Om nødvendig kan fullmektig overføre fullmakten videre til en tredjemann. Ved å fylle ut opplysninger under legger jeg inn min elektroniske signatur på denne fullmakt.',
      sv:
        'Jag / vi bemyndigar personen ovan att rösta på mina / våra aktier. Om det behövs kan fullmakten överföra fullmakten till tredje part. Genom att fylla i informationen nedan skickar jag in min elektroniska signatur på detta tillstånd.',
    },
    alternative: {
      en:
        'I hereby authorize the above to vote on my behalf at the annual meeting. If necessary, the proxy can transfer the power of attorney to a third party. By filling in the information below, I submit my electronic signature on this power of attorney.',
      nb:
        'Jeg gir med dette fullmakt til at ovennevnte kan stemme på vegne av megpå årsmøte. Om nødvendig kan fullmektig overføre fullmakten videre til en tredjemann. Ved å fylle ut opplysninger under legger jeg inn min elektroniske signatur på denne fullmakt.',
      sv:
        'Jag bemyndigar härmed ovan att rösta för min räkning vid årsmötet. Om nödvändigt kan fullmakten överföra fullmakten till tredje part. Genom att fylla i informationen nedan skickar jag in min elektroniska signatur på denna fullmakt.',
    },
  },
  VotingTieTextHelper: {
    default: {
      en:
        'The deciding vote of the meeting chair in case of a tie, per the Companies Act § 5-17 (1)',
      nb: 'Møteleders avgjørende stemme dersom stemmetallet er likt, jf. aksjeloven § 5-17 (1)',
      sv: 'Mötesordförandens avgörande röst vid oavgjort, enligt aktiebolagslagen § 5-17 (1)',
    },
    alternative: {
      en: 'The deciding vote of the meeting chair in case of a tie',
      nb: 'Møteleders avgjørende stemme dersom stemmetallet er likt',
      sv: 'Avgörande omröstning för mötesordförande vid oavgjort',
    },
  },
  sale: {
    default: {
      en: 'Purchase/sale',
      nb: 'Kjøp/salg',
    },
  },
  inheritance_without_tax_continuity: {
    default: {
      en: 'Inheritance without tax continuity',
      nb: 'Arv uten skattemessig kontinuitet',
    },
  },
  gift_without_tax_continuity: {
    default: {
      en: 'Gift without tax continuity',
      nb: 'Gave uten skattemessig kontinuitet',
    },
  },
  inheritance_gift_without_tax_continuity: {
    default: {
      en: 'Inheritance/gift without tax continuity',
      nb: 'Arv/gave uten skattemessig kontinuitet',
    },
  },
  taxable_inheritance_gift_with_tax_continuity: {
    default: {
      en: 'Taxable inheritance/gift with tax continuity',
      nb: 'Avgiftspliktig arv/gave m. skattemessig kontinuitet',
    },
  },
  gift_sale: {
    default: {
      en: 'Gift sale',
      nb: 'Gavesalg',
    },
  },
  group_internal_transaction: {
    default: {
      en: 'Internal transaction in group',
      nb: 'Konsernintern overføring',
    },
  },
  transaction_with_tax_continuity: {
    default: {
      en: 'Transaction with tax continuity',
      nb: 'Overføring med skattemessig kontinuitet',
    },
  },
  division_divorce: {
    default: {
      en: 'Distribution between spouses in the event of divorce',
      nb: 'Fordeling mellom ektefeller ved skilsmisse',
    },
  },
  establish: {
    default: {
      en: 'First issue',
      nb: 'Stiftelse',
    },
  },
  emission: {
    default: {
      en: 'Share issue',
      nb: 'Emisjon',
    },
  },
  'New share issue plan': {
    default: {
      en: 'New share issue plan',
      nb: 'Ny emisjonsplan',
    },
  },
  'Share issues': {
    default: {
      en: 'Share issues',
      nb: 'Emisjoner',
    },
  },
  import: {
    default: {
      en: 'Snapshot',
      nb: 'Øyeblikksbilde',
    },
  },
  transaction: {
    default: {
      en: 'Transaction',
      nb: 'Transaksjon',
    },
  },
  edit: {
    default: {
      en: 'Snapshot',
      nb: 'Øyeblikksbilde',
    },
  },
  YouHaveReceivedSharesInAnEmissionIn: {
    default: {
      en: 'Notice of entry in the shareholder register in',
      nb: 'Melding om innføring i aksjeeierbok i',
    },
  },
};
