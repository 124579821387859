export const DEFAULT_ORG_ID = 'ORGBRAIN_DEFAULT';
export const APP_SELECTOR_SCREEN = '/select';
export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const NO_LOGOUT_HEADER = 'X-Nologout-Interceptor';
export const CHANNEL_PULSE_PERIOD = 30000;
export const CHANNEL_PULSE_PERIOD_POLL = 1000;
// Numbers, letters, dashes
export const ORG_ID_REPLACE_REGEXP = /[^0-9a-zA-Z]/g;
export const ORG_ID_ALLOWED_REGEXP = /^[0-9a-zA-Z]+$/;
// https://invisible-characters.com/
// U+205F MEDIUM MATHEMATICAL SPACE
export const INVISIBLE_CHAR_SPACE_ALIKE = ' ';
