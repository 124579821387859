import { sharedenvironment } from './shared';

// TODO I am probably broken

const SERVER = 'devel-server.orgbrain.ai';
const GRAPHQL_SERVER = 'devel-server.orgbrain.ai';
const APP_BASE_URL = 'https://devel.staging.orgbrain.ai/';

const myenvironment = {
  production: false,
  name: 'TEST',
  adminServerUrl: `https://${SERVER}/admin`,
  graphqlUrl: `https://${GRAPHQL_SERVER}/graphql`,
  debug: true,
  fileApiUrl: `https://${SERVER}/admin`,
  appBase: APP_BASE_URL,
  //   jitsiMeetUrl: 'https://meet.orgbrain.ai/',
  GRAPHQL_ENDPOINT: `https://${SERVER}/graphql/graphql`,
  GRAPHQL_SUBSCRIPTIONS_WS_ENDPOINT: `wss://${SERVER}/wsgraphql/graphql`,
  meetingEmailRecipientDomain: 'staging-meeting.orgbrain.ai',
  cognitoUrl: `https://${SERVER}/admin`,
  taxDataUrl: 'https://taxdata-server.orgbrain.ai/tax',
  logoURL: 'https://orgbrain-beta-site.s3.eu-central-1.amazonaws.com/',
  webPushUrl: `https://${SERVER}/admin/web-push`,
  vapidPubKey:
    'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',
  bankid2FA: true,

  bankid: {
    client_id: 'test.kycsec.orgbrain.38990',
    redirect: `${APP_BASE_URL}auth/index.html`,
    auth: 'nbid',
    authURL: 'https://preprod.signicat.com/oidc/authorize',
  },

  azets: {
    client_id: 'orgbrain_sso',
    redirect: 'http://localhost:4200/auth-azets',
    authURL: 'https://idp-develop-devdb.staging.cozone.com/oauth/login',
    jwksURL: 'https://idp-develop-devdb.staging.cozone.com/api/v1/oauth2/jwks',
  },

  adobe_embedded_key: 'b4eb78f4e1b745829ed997bb3a0f1942',
  ENABLE_STRESS_SERVICE: true,
};

export const environment = Object.assign(sharedenvironment, myenvironment);
