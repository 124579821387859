export type CompanyType = {
  type: string;
  nb: string;
  en: string;
  selected?: boolean;
};
export const companyTypes = [
  {
    type: 'AAFY',
    nb: 'Ikke næringsdrivende virksomhet',
    en: 'No business activity',
  },
  {
    type: 'ADOS',
    nb: 'Administrativ enhet - offentlig sektor',
    en: 'Administrative entity - Public sector',
  },
  {
    type: 'ANNA',
    nb: 'Annen juridisk person',
    en: 'Other body corporate',
  },
  {
    type: 'ANS',
    nb: 'Ansvarlig selskap med solidarisk ansvar',
    en: 'General Partnership',
  },
  {
    type: 'AS',
    nb: 'Aksjeselskap',
    en: 'Limited company',
  },
  {
    type: 'ASA',
    nb: 'Allmennaksjeselskap',
    en: 'Public limited company',
  },
  {
    type: 'BA',
    nb: 'Selskap med begrenset ansvar',
    en: 'Company with limited liability',
  },
  {
    type: 'BBL',
    nb: 'Boligbyggelag',
    en: 'House building cooperative',
  },
  {
    type: 'BEDR',
    nb: 'Bedrift',
    en: 'Enterprise',
  },
  {
    type: 'BO',
    nb: 'Andre bo',
    en: 'Other estates',
  },
  {
    type: 'BRL',
    nb: 'Borettslag',
    en: 'Housing cooperative',
  },
  {
    type: 'DA',
    nb: 'Ansvarlig selskap med delt ansvar',
    en: 'General Partnership with shared liability',
  },
  {
    type: 'ENK',
    nb: 'Enkeltpersonforetak',
    en: 'Sole proprietorship',
  },
  {
    type: 'EØFG',
    nb: 'Europeisk økonomisk foretaksgruppe',
    en: 'European Economic Enterprise Group',
  },
  {
    type: 'ESEK',
    nb: 'Eierseksjonssameie',
    en: 'Condominium flat owner',
  },
  {
    type: 'FKF',
    nb: 'Fylkeskommunalt foretak',
    en: 'County municipal business enterprise',
  },
  {
    type: 'FLI',
    nb: 'Forening/lag/innretning',
    en: 'Association/club/organization',
  },
  {
    type: 'FYLK',
    nb: 'Fylkeskommune',
    en: 'County municipality',
  },
  {
    type: 'GFS',
    nb: 'Gjensidig forsikringsselskap',
    en: 'Mutual insurance company',
  },
  {
    type: 'IKJP',
    nb: 'Andre ikke-juridiske personer',
    en: 'Other non-body corporate',
  },
  {
    type: 'IKS',
    nb: 'Interkommunalt selskap',
    en: 'Inter-municipal company',
  },
  {
    type: 'KBO',
    nb: 'Konkursbo',
    en: 'Bankrupt estate',
  },
  {
    type: 'KF',
    nb: 'Kommunalt foretak',
    en: 'Municipal business enterprise',
  },
  {
    type: 'KIRK',
    nb: 'Den norske kirke',
    en: 'The Church of Norway',
  },
  {
    type: 'KOMM',
    nb: 'Kommune',
    en: 'Municipality',
  },
  {
    type: 'KS',
    nb: 'Kommandittselskap',
    en: 'Limited partnership',
  },
  {
    type: 'KTRF',
    nb: 'Kontorfellesskap',
    en: 'Joint office',
  },
  {
    type: 'NUF',
    nb: 'Norskregistrert utenlandsk foretak',
    en: 'Norwegian registered foreign business',
  },
  {
    type: 'OPMV',
    nb: 'Særskilt oppdelt enhet, jf. mval. § 2-2',
    en: 'Specially sectioned unit',
  },
  {
    type: 'ORGL',
    nb: 'Organisasjonsledd',
    en: 'Organizational section',
  },
  {
    type: 'PERS',
    nb: 'Andre enkeltpersoner som registreres i tilknyttet register',
    en: 'Other individuals registered in affiliated register',
  },
  {
    type: 'PK',
    nb: 'Pensjonskasse',
    en: 'Pension fund',
  },
  {
    type: 'PRE',
    nb: 'Partrederi',
    en: 'Jointly owned shipping company',
  },
  {
    type: 'SA',
    nb: 'Samvirkeforetak',
    en: 'Co-operative',
  },
  {
    type: 'SAM',
    nb: 'Tingsrettslig sameie',
    en: 'Joint ownership according to the law of property',
  },
  {
    type: 'SE',
    nb: 'Europeisk selskap',
    en: 'European company',
  },
  {
    type: 'SF',
    nb: 'Statsforetak',
    en: 'Public corporation',
  },
  {
    type: 'SPA',
    nb: 'Sparebank',
    en: 'Savings bank',
  },
  {
    type: 'STI',
    nb: 'Stiftelse',
    en: 'Foundation',
  },
  {
    type: 'STAT',
    nb: 'Staten',
    en: 'State',
  },
  {
    type: 'SÆR',
    nb: 'Annet foretak ifølge særskilt lov',
    en: 'Other business enterprise in accordance with special legislation',
  },
  {
    type: 'TVAM',
    nb: 'Tvangsregistrert for MVA',
    en: 'Compulsory registration in the Value Added Tax Register',
  },
  {
    type: 'VPFO',
    nb: 'Verdipapirfond',
    en: 'Unit trusts',
  },
  {
    type: 'SUBGR-GENERAL',
    nb: 'Generell gruppe',
    en: 'General group',
    sv: 'Allmän grupp',
  },
  {
    type: 'SUBGR-STYR',
    nb: 'Styringsgruppe',
    en: 'Steering group',
    sv: 'Styrgrupp',
  },
  {
    type: 'SUBGR-LEDER',
    nb: 'Ledergruppe',
    en: 'Management group',
    sv: 'Ledningsgrupp',
  },
  {
    type: 'SUBGR-VALGKOMITE',
    nb: 'Valgkomite',
    en: 'Nomination committee',
    sv: 'Valberedning',
  },
  {
    type: 'SUBGR-RENUM',
    nb: 'Godtgjørelsesutvalg',
    en: 'Remuneration committee',
    sv: 'Ersättningskommitté',
  },
  {
    type: 'SUBGR-KONTROL',
    nb: 'Kontrollutvalg',
    en: 'Control committee',
    sv: 'Kontrollkommitté',
  },
  {
    type: 'SUBGR-REVISJON',
    nb: 'Revisjonsutvalg',
    en: 'Audit committee',
    sv: 'Revisionskommitté',
  },
  {
    type: 'SUBGR-PROJECT',
    nb: 'Prosjektgruppe',
    en: 'Project group',
    sv: 'Projektgrupp',
  },
  {
    type: 'SUBGR-WORKGROUP',
    nb: 'Arbeidsgruppe',
    en: 'Working group',
    sv: 'Arbetsgrupp',
  },
];

export function guessOrgTypeFromOrgName({
  orgName,
  avoidDefaultOrgType,
}: {
  orgName: string;
  avoidDefaultOrgType?: boolean;
}) {
  let result = undefined;
  if (orgName && typeof orgName === 'string') {
    const tokens = orgName.split(/\s+/);
    const lastToken = tokens.length && tokens[tokens.length - 1].toUpperCase();
    const exists = companyTypes.find((item) => item.type === lastToken);
    if (exists) {
      result = lastToken;
    }
  }

  if (!result && !avoidDefaultOrgType) {
    result = 'AS';
  }

  return result;
}
