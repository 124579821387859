export enum MfaMethodsEnum {
  COGNITO = 'COGNITO',
  SMS = 'SMS',
  BANKID = 'BANKID',
  TOTP = 'TOTP',
  TRUSTED = 'TRUSTED',
  COZONE = 'COZONE',
}

export type CredentialKeys = {
  [key in MfaMethodsEnum]: string; // Note that "key in".
};

// Data required for creating a session

export class SessionDataInit {
  _id?: string;
  userId: string;
  ip: string;
  expires?: string;
  // Device
  deviceId: string;

  //  session
  sessionToken: string; // current session  (same as cookie)
  sub: string; //  Cognito sub
  email: string; //  user email
  slidePeriod: number; // millis for sliding window
  credentials: CredentialKeys; //
  idToken: string;
}

export class SessionData extends SessionDataInit {
  // Device
  deviceId: string;

  //  session

  lastActivity: string;

  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  // temporary stuff
  sessionActive?: boolean;
}

export class DeviceData {
  _id?: string;
  userId: string;

  // Device
  ip: string;
  fingerprint: number;
  trusted: boolean;
  deviceToken: string;
  name: string;

  expires: string;
  //  TODO probably just need expires
  deleted?: boolean;

  // tTO pas the front end someting compact.
  sessions?: SessionData[];
  mydevice?: boolean;
  createdAt?: string;
  updatedAt?: string;
  virgin: boolean;
}
