export enum ModuleEnum {
  meetings = 'meetings',
  shareholders = 'shareholders',
  news = 'news',
  files = 'files',
  boardschool = 'boardschool',
  surveys = 'surveys',
  organization = 'organization',
  configure = 'configure',
  admin = 'admin',
}
