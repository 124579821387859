export const textMappings = {
  Reminder: {
    en: 'Reminder',
    nb: 'Påminnelse',
    sv: 'Påminnelse',
  },

  and: {
    en: 'and',
    nb: 'og',
    sv: 'och',
  },
  of: {
    en: 'of',
    nb: 'av',
    sv: 'av',
  },
  Page: {
    en: 'Page',
    nb: 'Side',
    sv: 'Sida',
  },
  Pages: {
    en: 'Pages',
    nb: 'Sider',
    sv: 'Sidor',
  },
  'This document package contains': {
    en: 'This document package contains',
    nb: 'Denne dokumentpakken inneholder',
  },
  'The original document': {
    en: 'The original document',
    nb: 'Det originale dokumentet',
  },
  'This signature page at the end': {
    en: 'This signature page at the end',
    nb: 'Denne signatursiden på slutten',
  },
  'Signature pages at the end': {
    en: 'Signature pages at the end',
    nb: 'Signatur sidene på slutten',
  },
  'COUNTRY:NO': {
    en: 'Norway',
    nb: 'Norge',
    sv: 'Norge',
  },
  'COUNTRY:SE': {
    en: 'Sweden',
    nb: 'Sverige',
    sv: 'Sverige',
  },
  'COUNTRY:DK': {
    en: 'Denmark',
    nb: 'Danmark',
    sv: 'Danmark',
  },
  EMAIL_ATTACHMENTS: {
    en: 'Email attachments',
    nb: 'E-postvedlegg',
    sv: 'E-postbilagor',
  },
  ANNUAL_GENERAL_MEETING: {
    en: 'Annual general meetings',
    nb: 'Ordinære generalforsamlinger',
    sv: 'Årsstämma',
  },
  SHAREHOLDER_MEETING: {
    en: 'Shareholder meetings',
    nb: 'Aksjonærmøter',
    sv: 'Aktieägarmöten',
  },
  BOARD_MEETING: {
    en: 'Board meetings',
    nb: 'Styremøter',
    sv: 'Styrelsemöten',
  },
  EXTRAORDINARY_GENERAL_MEETING: {
    en: 'Extraordinary general meetings',
    nb: 'Ekstraordinære generalforsamlinger',
    sv: 'Extra bolagsstämma',
  },
  OTHER_MEETING: {
    en: 'Meetings',
    nb: 'Møter',
    sv: 'Möten',
  },
  MEETINGS: {
    en: 'Meetings',
    nb: 'Møter',
    sv: 'Möten',
  },
  PROXY_FOLDER: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  MINUTES: {
    en: 'Minutes',
    nb: 'Protokoll',
    sv: 'Protokoll',
  },
  ATTACHMENTS: {
    en: 'Attachments',
    nb: 'Vedlegg',
    sv: 'Bilagor',
  },
  DRAFT: {
    en: 'Draft',
    nb: 'Kladd',
    sv: 'Förslag',
  },
  PLANNED: {
    en: 'Planned',
    nb: 'Planlagt',
    sv: 'Planerad',
  },
  CALLED: {
    en: 'Called',
    nb: 'Innkalt',
    sv: 'Kallad',
  },
  ACTIVE: {
    en: 'Active',
    nb: 'Pågår',
    sv: 'Aktiva',
  },
  ANNUAL_PLAN: {
    en: 'Annual plan',
    nb: 'Årsplan',
    sv: 'Årlig plan',
  },
  REQUIRES_MINUTES: {
    en: 'Requires minutes',
    nb: 'Mangler protokoll',
    sv: 'Kräver protokoll',
  },
  REQUIRES_DISCUSSION: {
    en: 'Requires comments',
    nb: 'Protokoll på høring',
    sv: 'Kräver kommentarer',
  },
  REQUIRES_SIGNATURES: {
    en: 'Requires signatures',
    nb: 'Mangler signaturer',
    sv: 'Kräver underskrifter',
  },
  COMPLETED: {
    en: 'Completed',
    nb: 'Gjennomført',
    sv: 'Avslutad',
  },
  TEST: {
    en: 'Trial',
    nb: 'Prøve',
    sv: 'Rättegång',
  },
  SUSPENDED: {
    en: 'Expired',
    nb: 'Utgått',
    sv: 'Utgånget',
  },
  GRUNDER: {
    en: 'Founder',
    nb: 'Grunder',
    sv: 'Grundare',
  },
  SMALL_BUSINESS: {
    en: 'Small business',
    nb: 'Liten bedrift',
    sv: 'Litet företag',
  },
  BASIS: {
    en: 'Basic',
    nb: 'Basis',
    sv: 'Grundläggande',
  },
  ENTERPRISE: {
    en: 'Enterprise',
    nb: 'Enterprise',
    sv: 'Företag',
  },
  PersonalFiles: {
    en: 'Personal files',
    nb: 'Personlige filer',
    sv: 'Personliga filer',
  },
  MeetingFiles: {
    en: 'Meeting files',
    nb: 'Møtefiler',
    sv: 'Mötesfiler',
  },
  all_meeting: {
    en: 'Board',
    nb: 'Styre',
    sv: 'Styrelse',
  },
  ga_meetings: {
    en: 'Owner/Member',
    nb: 'Eier/Medlem',
    sv: 'Ägare / medlem',
  },
  admin: {
    en: 'Admin',
    nb: 'Admin',
    sv: 'Administration',
  },
  buyer: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },
  Enable: {
    en: 'Enable',
    nb: 'Aktiver',
    sv: 'Gör det möjligt',
  },
  Disable: {
    en: 'Disable',
    nb: 'Deaktiver',
    sv: 'Inaktivera',
  },
  Yes: {
    en: 'Yes',
    nb: 'Ja',
    sv: 'Ja',
  },
  No: {
    en: 'No',
    nb: 'Nei',
    sv: 'Nej',
  },
  'Meeting type': {
    en: 'Meeting type',
    nb: 'Møtetype',
    sv: 'Möte typ',
  },
  PHYSICAL_MEETING: {
    en: 'Physical meeting',
    nb: 'Fysisk møte',
    sv: 'Fysiskt möte',
  },
  PHONE_MEETING: {
    en: 'Phone meeting',
    nb: 'Telefonmøte',
    sv: 'Telefonkonferens',
  },
  HYBRID_MEETING: {
    en: 'Hybrid meeting',
    nb: 'Hybridmøte',
    sv: 'Hybrid möte',
  },
  DIGITAL_MEETING: {
    en: 'Digital meeting',
    nb: 'Digitalt møte',
    sv: 'Digital möte',
  },
  VIDEO_CONFERENCE: {
    en: 'Video conference',
    nb: 'Videokonferanse',
    sv: 'Videokonferens',
  },
  WRITTEN_PROCEDURE: {
    en: 'Written procedure',
    nb: 'Skriftlig behandling',
    sv: 'Skriftligt förfarande',
  },
  OTHER_PROCEDURE: {
    en: 'Other procedure',
    nb: 'Annen møteform',
    sv: 'Annat förfarande',
  },
  Date: {
    en: 'Date',
    nb: 'Dato',
    sv: 'Datum',
  },
  Location: {
    en: 'Location',
    nb: 'Sted',
    sv: 'Plats',
  },
  'Proposed resolution': {
    en: 'Proposed resolution',
    nb: 'Forslag til vedtak',
    sv: 'Föreslagen resolution',
  },
  For: {
    en: 'For',
    nb: 'For',
    sv: 'För',
  },
  Against: {
    en: 'Against',
    nb: 'Imot',
    sv: 'Mot',
  },
  'General comments': {
    en: 'General comments',
    nb: 'Generelle kommentarer',
    sv: 'Generella kommentarer',
  },
  Participants: {
    en: 'Participants',
    nb: 'Deltakere',
    sv: 'Deltagarna',
  },
  'Did not participate': {
    en: 'Did not participate',
    nb: 'Deltok ikke',
    sv: 'Deltog inte',
  },
  'Chair of the meeting': {
    en: 'Chair of the meeting',
    nb: 'Møteleder',
    sv: 'Ordförande för mötet',
  },
  'In the event of a tie, the decision is made by a built-in lottery': {
    en: 'In the event of a tie, the decision is made by a built-in lottery',
    nb: 'Ved stemmelikhet, treffes avgjørelsen ved en innebygget loddtrekning',
    sv: 'I händelse av oavgjort fattas beslutet av ett inbyggt lotteri',
  },
  minutesInfoForProcedureTypePhysicalMeeting: {
    en: 'The proceedings were carried out as a meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et møte.',
    sv: 'Förfarandet genomfördes som ett möte.',
  },
  minutesInfoForProcedureTypeHybridMeeting: {
    en: 'The proceedings were carried out as a hybrid meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et hybridmøte.',
    sv: 'Förfarandet genomfördes som ett hybridmöte.',
  },
  minutesInfoForProcedureTypeDigitalMeeting: {
    en: 'The proceedings were carried out as a digital meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et digitalt møte.',
    sv: 'Förfarandet genomfördes som ett digital möte.',
  },

  minutesInfoForProcedureTypePhoneMeeting: {
    en: 'The proceedings were carried out as a phone meeting.',
    nb: 'Styrebehandlingen ble gjennomført per telefon.',
    sv: 'Förfarandet genomfördes som ett telefonmöte.',
  },
  minutesInfoForProcedureTypeVideoConference: {
    en: 'The proceedings were carried out as a video conference.',
    nb: 'Styrebehandlingen ble gjennomført som en videokonferanse.',
    sv: 'Förfarandet genomfördes som en videokonferens.',
  },
  minutesInfoForProcedureTypeWrittenProcedure: {
    en: 'The proceedings were carried out in writing without a meeting.',
    nb: 'Styrebehandlingen ble gjennomført skriftlig og uten møte.',
    sv: 'Förfarandet genomfördes skriftligen utan möte.',
  },
  MeetingVotedOnAndApproved: {
    en: 'The following decision was voted on and approved:',
    nb: 'Følgende forslag ble stemt over og vedtatt:',
    sv: 'Följande beslut röstades om och godkändes:',
  },
  MeetingVotedOnAndRejected: {
    en: 'The following decision was voted on but rejected:',
    nb: 'Følgende forslag ble stemt over, men fikk ikke flertall:',
    sv: 'Följande beslut röstades om men avvisades:',
  },
  MeetingVotePassedAllFor: {
    en: 'There was an unanimous vote in favour of the following decision:',
    nb: 'Følgende forslag ble enstemmig vedtatt:',
    sv: 'Följande beslut röstades enhälligt för:',
  },
  MeetingVotePassedWithAllDecisionsUnanimous: {
    en: 'The following decision was made:',
    nb: 'Det ble fattet følgende vedtak:',
    sv: 'Följande beslut fattades:',
  },
  MeetingVoteRjectedAllAgainst: {
    en: 'There was an unanimous vote against the following decision:',
    nb: 'Følgende forslag ble enstemmig nedstemt:',
    sv: 'Följande beslut röstades enhälligt emot:',
  },
  MeetingThoseForDecision: {
    en: 'The following voted in favour of the decision:',
    nb: 'Følgende stemte for forslaget:',
    sv: 'Följande röstade för beslutet:',
  },
  MeetingThoseAgainstDecision: {
    en: 'The following voted against the decision:',
    nb: 'Følgende stemte imot forslaget:',
    sv: 'Följande röstade emot beslutet:',
  },
  MeetingThoseNeutralDecision: {
    en: 'The following voted neutral:',
    nb: 'Følgende avga ikke stemme:',
    sv: 'Följande röstade neutralt:',
  },
  MeetingAllUnanimousStatement: {
    en: 'All decisions were passed unanimously.',
    nb: 'Det var enstemmighet i alle avstemningene.',
    sv: 'Alla beslut fattades enhälligt.',
  },
  TheFollowingMustSignRead: {
    en: 'The following must sign that the minutes have been read:',
    nb: 'Følgende skal signere at protokollen er lest:',
    sv: 'Följande måste underteckna att protokollet har lästs:',
  },
  theFollowingWasApproved: {
    en: 'The following was approved',
    nb: 'Følgende ble vedtatt',
    sv: 'Följande godkändes',
  },
  theFollowingWasRejected: {
    en: 'The following was rejected',
    nb: 'Følgende ble nedstemt',
    sv: 'Följande avvisades',
  },
  overviewOfVoting: {
    en: 'Overview of voting',
    nb: 'Oversikt over stemmegiving',
    sv: 'Översikt över omröstning',
  },
  Case: {
    en: 'Case',
    nb: 'Sak',
    sv: 'Fall',
  },
  Shares: {
    en: 'Shares',
    nb: 'Aksjer',
    sv: 'Aktier',
  },
  'In person': {
    en: 'In person',
    nb: 'Personlig',
    sv: 'I person',
  },
  Proxy: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  'Represented by': {
    en: 'Represented by',
    nb: 'Representert ved',
    sv: 'Representerad av',
  },
  'Follow-up case': {
    en: 'Follow-up case',
    nb: 'Oppfølgingssak',
    sv: 'Uppföljningsfall',
  },
  Description: {
    en: 'Description',
    nb: 'Beskrivelse',
    sv: 'Beskrivning',
  },
  'Responsible for follow-up': {
    en: 'Responsible for follow-up',
    nb: 'Ansvarlig for oppfølging',
    sv: 'Ansvarig för uppföljning',
  },
  'Follow-up date': {
    en: 'Follow-up date',
    nb: 'Oppfølgingsdato',
    sv: 'Uppföljningsdatum',
  },
  'Elected by drawing lots': {
    en: 'Elected by drawing lots',
    nb: 'Valgt ved loddtrekning',
    sv: 'Vald genom att dra lod',
  },
  Elected: {
    en: 'Elected',
    nb: 'Valgt',
    sv: 'Vald',
  },
  'Elected candidates': {
    en: 'Elected candidates',
    nb: 'Valgte kandidater',
    sv: 'Valda kandidater',
  },
  orderOfCandidatesWSithSameNumberOfVotesDecidedRandomly: {
    en: 'The order of candidates with the same number of votes was decided by drawing lots.',
    nb:
      'Rekkefølgen på kandidatene med likt antall stemmer er avgjort av systemet ved en tilfeldig loddtrekning.',
    sv: 'Ordningen på kandidater med samma antal röster bestämdes genom att dra lod.',
  },
  'On election': {
    en: 'On election',
    nb: 'På valg',
    sv: 'Vid valet',
  },
  position_electionResults: {
    en: 'Position',
    nb: 'Plassering',
    sv: 'Placera',
  },
  'Number of votes': {
    en: 'Number of votes',
    nb: 'Antall stemmer',
    sv: 'Röstetal',
  },
  'of votes represented': {
    en: 'of votes represented',
    nb: 'av stemmer representert',
    sv: 'av de representerade rösterna',
  },
  Candidate: {
    en: 'Candidate',
    nb: 'Kandidat',
    sv: 'Kandidat',
  },
  'Votes cast': {
    en: 'Votes cast',
    nb: 'Stemmer avgitt',
    sv: 'Avgivna röster',
  },
  'Voted for': {
    en: 'Voted for',
    nb: 'Stemte for',
    sv: 'Röstade på',
  },
  'of votes cast': {
    en: 'of votes cast',
    nb: 'av stemmer avgitt',
    sv: 'av avgivna röster',
  },
  'Total represented': {
    en: 'Total represented',
    nb: 'Totalt representert',
    sv: 'Totalt representerat',
  },
  'Voted against': {
    en: 'Voted against',
    nb: 'Stemte mot',
    sv: 'Röstade emot',
  },
  Abstained: {
    en: 'Abstained',
    nb: 'Avstått',
    sv: 'Avstod',
  },
  Result: {
    en: 'Result',
    nb: 'Resultat',
    sv: 'Resultat',
  },
  Approved: {
    en: 'Approved',
    nb: 'Vedtatt',
    sv: 'Godkänd',
  },
  Rejected: {
    en: 'Rejected',
    nb: 'Nedstemt',
    sv: 'avvisade',
  },
  voteRuleExplanationStatutoryChange: {
    en: 'in accordance with majority requirement rules',
    nb: 'i overenstemmelse med flertallskrav',
    sv: 'i enlighet med regler för majoritetskrav',
  },
  voteRuleExplanationQualifiedMajority: {
    en: 'in accordance with qualified majority requirement',
    nb: 'i overenstemmelse med krav til kvalifisert flertall',
    sv: 'i enlighet med kvalificerad majoritet',
  },
  voteRuleExplanationUnanimity: {
    en: 'in accordance with unanimity requirement',
    nb: 'i overenstemmelse med krav til enstemmighet',
    sv: 'i enlighet med enhällighetskrav',
  },
  voteRuleExplanationInvestigation: {
    en: 'in accordance with vote approval requirement for proposition for investigation cases',
    nb: 'i overenstemmelse med stemmekrav for forslag om gransking',
    sv: 'i enlighet med kravet på röstgodkännande för förslag till utredningsärenden',
  },
  'Represented directly': {
    en: 'Represented directly',
    nb: 'Representert direkte',
    sv: 'Representeras direkt',
  },
  'Represented by pre-voting': {
    en: 'Represented by pre-voting',
    nb: 'Representert ved forhåndsstemme',
    sv: 'Representeras av förhandsröstning',
  },
  'Represented by joining': {
    en: 'Represented by joining',
    nb: 'Representert ved oppmøte',
    sv: 'Representeras genom att gå med',
  },
  'Voting shares': {
    en: 'Voting shares',
    nb: 'Stemmeberettigede aksjer',
    sv: 'Röstningsandelar',
  },
  'of voting-eligible shares': {
    en: 'of voting-eligible shares',
    nb: 'av stemmeberettigede aksjer',
    sv: 'av röstberättigade aktier',
  },
  'own shares': {
    en: 'own shares',
    nb: 'egne aksjer',
    sv: 'egna aktier',
  },
  'Represented by proxy': {
    en: 'Represented by proxy',
    nb: 'Representert ved fullmakt',
    sv: 'Representeras av ombud',
  },
  'Reminder of meeting for': {
    en: 'Reminder of meeting for',
    nb: 'Påminnelse om møte for',
    sv: 'Påminnelse om möte för',
  },
  'AllRequirementsFullfilled?companyType=FLI': {
    en: 'All the formal requirements for notice and number of attendance are fulfilled.',
    nb: 'Alle de formelle krav til innkalling og antall deltagere er oppfylt.',
    sv: 'Alla formella krav för uppsägning och antal närvaro är uppfyllda.',
  },
  'AllRequirementsFullfilled?jurisdiction=NO': {
    en: 'The proceeding fulfills the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?companyType=ASA': {
    en:
      'The proceeding fulfills the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?jurisdiction=EN': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen.',
  },
  'AllRequirementsFullfilled?jurisdiction=SE': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsFullfilled?jurisdiction=DK': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 124.',
  },
  'AllRequirementsNotFullfilled?companyType=FLI': {
    en: 'The formal requirements for notice and/or number of attendance are not fulfilled.',
    nb: 'De formelle krav til innkalling og/eller antall deltagere er ikke oppfylt.',
    sv: 'De formella kraven för anmälan och / eller antal närvaro uppfylls inte.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=NO': {
    en:
      'The proceeding does not meet the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i norska aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?companyType=ASA': {
    en:
      'The proceeding does not meet the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=EN': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=SE': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=DK': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 124.',
  },
  'There is quorum, decisions can be taken': {
    en: 'There is quorum, decisions can be taken',
    nb: 'Styret er beslutningsdyktig',
    sv: 'Det finns beslutsförmåga, beslut kan fattas',
  },
  'There is not a quorum, decisions cannot be taken': {
    en: 'There is not a quorum, decisions cannot be taken',
    nb: 'Styret er ikke beslutningsdyktig',
    sv: 'Det finns inte beslutsfattande, beslut kan inte fattas',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=NO': {
    en:
      'There was a quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv:
      'Det var beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?companyType=ASA': {
    en:
      'There was a quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv:
      'Det var beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=EN': {
    en:
      'There was a quorum, and the requirements listed in the Companies Act for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det var beslutsfattande och kraven i företagslagen för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=SE': {
    en:
      'There was a quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv:
      'Det var beslutsfattande och kraven i aktiebolagslagen kap. 8 § 21 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=DK': {
    en:
      'There was a quorum, and the requirements listed in the Companies Act § 124 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til selskabsloven § 124.',
    sv:
      'Det var beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut uppfylldes.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=NO': {
    en:
      'There was no quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv:
      'Det fanns inget beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?companyType=ASA': {
    en:
      'There was no quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv:
      'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=EN': {
    en:
      'There was no quorum, and the requirements listed in the Companies Act for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven.',
    sv:
      'Det fanns inget beslutsfattande, och kraven i företagslagen för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=SE': {
    en:
      'There was no quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv:
      'Det fanns inget beslutsfattande och de krav som anges i aktiebolagslagen kap. 8 § 21 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=DK': {
    en:
      'There was no quorum, and the requirements listed in the Companies Act § 124 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til selskabsloven § 124.',
    sv:
      'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut var som sådan inte uppfyllda.',
  },
  isSubsidiaryTextFli: {
    en: 'Shall not exercise voting rights',
    nb: 'Skal ikke utøve stemmerett',
    sv: 'Ska inte utöva rösträtt',
  },
  isSubsidiaryText: {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt',
  },
  'isSubsidiaryText?jurisdiction=NO': {
    en:
      'Is a subsidiary of {{orgName}} and shall not exercise voting rights, cf. the Norwegian Companies Act § 5-3 (3)',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett, jf. aksjeloven § 5-3 (3)',
    sv:
      'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt, jfr. den norska aktiebolagslagen § 5-3 (3)',
  },
  cantUpdateMeetingStatusTooltip: {
    en:
      'Unfortunately, you have to contact the meeting administrator to change your attending status. This is because the meeting is no longer in status "Called"',
    nb:
      'Du må dessverre kontakte møte-administrator for å endre din deltakerstatus. Dette fordi møtet ikke lenger er i status "Innkalt"',
    sv:
      'Tyvärr måste du kontakta mötesadministratören för att ändra din deltagarstatus. Detta beror på att mötet inte längre är i status "Called"',
  },
  VotingTieTextHelper2: {
    en: "tie; final result is based on the meeting chair's deciding vote",
    nb: 'likt stemmetall; endelig resultat er basert på møteleders avgjørende stemme',
    sv: 'slips; slutresultatet baseras på mötesordförandens avgörande röst',
  },
  'No info': {
    en: 'No info',
    nb: 'Ingen info',
    sv: 'Ingen info',
  },
  'Rendering Failure': {
    en: 'Rendering Failure',
    nb: 'Gjengivelsesfeil',
    sv: 'Rendering misslyckande',
  },
  Send: {
    en: 'Send',
    nb: 'Sende',
    sv: 'Skicka',
  },
  Reject: {
    en: 'Reject',
    nb: 'Avvis',
    sv: 'Avvisa',
  },
  Complaint: {
    en: 'Complaint',
    nb: 'Klage',
    sv: 'Klagomål',
  },
  Bounce: {
    en: 'Bounce',
    nb: 'Sprette',
    sv: 'Studsa',
  },
  Delivery: {
    en: 'Delivery',
    nb: 'Leveranse',
    sv: 'Leverans',
  },
  Open: {
    en: 'Open',
    nb: 'Åpen',
    sv: 'Öppna',
  },
  Click: {
    en: 'Click',
    nb: 'Klikk',
    sv: 'Klick',
  },
  OrgbrainSignFooter: {
    en:
      'This document is signed with Orgbrain eSign\n- a standard electronic signature compliant\nwith EU regulation 910/2014 (eIDAS).',
    nb:
      'Dokumentet er signert med Orgbrain eSign\n- en standard elektronisk signatur i samsvar\nmed EU-forordning 910/2014 (eIDAS).',
  },

  OrgbrainSignQRFooter: {
    en: 'For more information see\n \nOr scan the QR code',
    nb: 'For mer informasjon se\n \nEller skann QR koden',
  },

  OrgbrainSignHeader: {
    en:
      'This document is signed with Orgbrain eSign - a standard electronic\nsignature compliant with EU regulation 910/2014 (eIDAS).',
    nb:
      'Dokumentet er signert med Orgbrain eSign - en standard elektronisk\nsignatur i samsvar med EU-forordning 910/2014 (eIDAS).',
  },

  SignicatRequestHeader: {
    en: 'Orgbrain Signing Request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskTitle: {
    en: 'Orgbrain signing request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskMessage: {
    en: 'Please sign this document',
    nb: 'Vennligst signer dette dokumentet',
  },

  FormalitiesTopic: {
    en: 'Formalities',
    nb: 'Formalia',
  },
  ReportingTopic: {
    en: 'Reporting',
    nb: 'Rapportering',
  },
  EconomyTopic: {
    en: 'Economy',
    nb: 'Økonomi',
  },
  PersonnelTopic: {
    en: 'Personnel',
    nb: 'Personale',
  },
  AppointmentsTopic: {
    en: 'Appointments',
    nb: 'Avtaler',
  },
  SupervisionTopic: {
    en: 'Supervision',
    nb: 'Tilsyn',
  },
  ShareholdersTopic: {
    en: 'Owners/Members',
    nb: 'Eiere/Medlemmer',
  },
  CEOTopic: {
    en: 'CEO',
    nb: 'Daglig leder',
  },
  StrategyTopic: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ProxyTopic: {
    en: 'Power of attorney',
    nb: 'Fullmakter',
  },
  StatutesTopic: {
    en: 'Statutes',
    nb: 'Vedtekter',
  },
  NewMessageFrom: {
    en: 'New message from',
    nb: 'Ny melding fra',
  },
  ClickHereToViewTheMessage: {
    en: 'Click here to view the message',
    nb: 'Klikk her for å se meldingen',
  },
  'Email address update': {
    en: 'Email address update',
    nb: 'Oppdatering av e-postadresse',
  },
  'Please use the code below to confirm your new email address': {
    en: 'Please use the code below to confirm your new email address',
    nb: 'Vennligst bekreft din nye e-postadresse ved å benytte koden under',
  },
  resetPassword: {
    en: 'Reset password',
    nb: 'Tilbakestille passord',
  },
  weReceivedRequest: {
    en: 'We have received a request to reset the password for',
    nb: 'Vi har mottatt en forespørsel om å tilbakestille passordet for',
  },
  pleaseUseLink: {
    en: 'Please use the following link',
    nb: 'Bruk følgende lenke',
  },
  CLICKHERETOSTARTSURVEY: {
    en: 'Click Start to respond to the survey',
    nb: 'Klikk på Start for å svare på undersøkelsen',
  },
  HelloEmailText: {
    en: 'Hi',
    nb: 'Hei',
  },
  InvitedToSurveyText: {
    en: 'You have been invited to respond to a survey for {{ORG}}. The survey is anonymous.',
    nb: 'Du er blitt invitert til å svare på en undersøkelse for {{ORG}}. Undersøkelsen er anonym.',
  },
  ReminderForSigningRequest: {
    en: 'Reminder for signing request',
    nb: 'Påminnelse om signeringsforespørsel',
  },

  GaveActionPointResponsibility: {
    en: 'gave you responsibility for an action point:',
    nb: 'ga deg ansvar for et aksjonspunkt:',
  },
  WithDeadline: {
    en: 'With a deadline:',
    nb: 'Med frist:',
  },
  YouHaveResponsibilityForANewActionPoint: {
    en: 'You are responsible for a new action point',
    nb: 'Du har fått ansvar for et nytt aksjonspunkt',
  },
  SharesHaveBeenTransferredToYou: {
    en: 'Shares have been transferred to you',
    nb: 'Aksjer har blitt overført til deg',
  },
  SomeOfYourSharesHasBeenTransferredToSomeoneElse: {
    en: 'Your shares has been transferred',
    nb: 'Dine aksjer har blitt overført',
  },
  SharesWithClass: {
    en: 'shares, with class',
    nb: 'aksjer, med aksjeklasse',
  },
  HasBeenRegisteredToYou: {
    en: 'has been registered on you in an emission',
    nb: 'har blitt registrert på deg i en emisjon',
  },
  ShareWithClass: {
    en: 'share, with class',
    nb: 'aksje, med aksjeklasse',
  },
  OfYourSharesWithClass: {
    en: 'of your shares, with class',
    nb: 'av dine aksjer, med aksjeklasse',
  },
  in: {
    en: 'in',
    nb: 'i',
  },
  HasBeenTransferredToYouFrom: {
    en: 'has been transferred to you from',
    nb: 'har blitt overført til deg fra',
  },
  HasBeenTransferredTo: {
    en: 'has been transferred to',
    nb: 'har blitt overført til',
  },
  WeWantToInformYouThat: {
    en: 'We would like to inform you that',
    nb: 'Vi vil med dette opplyse deg at',
  },
  HasBeenRegisteredAsBeneficialOwner: {
    en: 'has been registered as a beneficial owner with',
    nb: 'er registrert som reell rettighetshaver hos',
  },
  ByBeneficialOwnerMeaning: {
    en:
      'The law defines a beneficial owner as a natural person or persons who ultimately owns or controls a legal person, event, entity or other association.',
    nb:
      'Med reell rettighetshaver menes i loven den eller de fysiske personene som i siste instans eier eller kontrollerer en juridisk person, arrangement, enhet eller annen sammenslutning.',
  },
  YouHaveBeenRegisteredAsABeneficialOwner: {
    en: 'You have been registered as a beneficial owner',
    nb: 'Du har blitt registrert som reell rettighetshaver',
  },
  ReadMoreHere: {
    en: 'Read more',
    nb: 'Les mer',
  },
};
