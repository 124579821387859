export enum SigningEvent {
  TASK_CREATED = 'TASK_CREATED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_REJECTED = 'TASK_REJECTED',
  ORDER_CREATED = 'ORDER_CREATED',
  PACKAGING_COMPLETED = 'PACKAGING_COMPLETED',
  TASK_EXPIRED = 'TASK_EXPIRED',
}
export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}
export enum NotificationType {
  ACTION_AUTO = 'ACTION_AUTO', // requires an action that will remove notification     No bin.   Remove after time out.
  ACTION_MANUAL = 'ACTION_MANUAL', // requires an action but user must remove notification.
  INFORMATION = 'INFORMATION', // information  user
}
export class NotificationTemplate {
  title: { en: string; nb: string };
  code: string;
  data: any;
  templates?: { en: string; nb: string };
  type: NotificationType;
}

export const notificationCodes: { [code: string]: NotificationTemplate } = {
  SigningTaskCreatedInviteeSignicat: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Signing Task',
      nb: 'Signeringsoppgave',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en:
        '<p> {inviterName} is requesting that you <a href={url} target="_blank">sign</a> the document <b>{documentName}</b>.</p> ',
      nb:
        '<p>{inviterName} ber deg <a href= {url} target = "_blank">signere</a> dokumentet <b>{documentName}</b>.</p>',
    },
    type: NotificationType.ACTION_AUTO,
  },

  SurveyInvite: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Survey Task',
      nb: 'Spørreundersøkelse Oppgave',
    },
    code: '',
    data: class {
      constructor(public surveyName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en:
        '<p> {inviterName} is requesting that you <a href={url} target="_blank">to do</a> a survey: <b>{surveyName}</b>.</p> ',
      nb:
        '<p>{inviterName} ber deg <a href= {url} target = "_blank">gjennomføre</a> en spørreundersøkelse: <b>{surveyName}</b>.</p>',
    },
    type: NotificationType.ACTION_AUTO,
  },

  SigningTaskCreatedInviteeV2: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Signing Task',
      nb: 'Signeringsoppgave',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en:
        '<p> {inviterName} is requesting that you <a href={url} target="_blank">sign</a> the document <b>{documentName}</b>.</p> ',
      nb:
        '<p>{inviterName} ber deg <a href= {url} target = "_blank">signere</a> dokumentet <b>{documentName}</b>.</p>',
    },
    type: NotificationType.ACTION_AUTO,
  },
  SigningTaskCreatedInviter: {
    title: {
      // TRANSLATE SigningTaskCreatedInviterTitle
      en: 'Signing Task Sent',
      nb: 'Signeringsoppgave sendt',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url, public inviteeName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInviterTemplate
      en: '<p> You invited {inviteeName} to sign the document <b>{documentName}</b>. </p> ',
      nb: '<p> Du inviterte {inviteeName} til å signere dokumentet <b>{documentName}</b>. </p> ',
    },
    type: NotificationType.INFORMATION,
  },
  // SigningTaskCreatedInviterV2: {
  //   title: {
  //     // TRANSLATE SigningTaskCreatedInviterTitle
  //     en: 'Signing Task (esign) Sent',
  //     nb: 'Signeringsoppgave sendt',
  //   },
  //   code: '',
  //   data: class {
  //     constructor(public documentName: string, public url, public inviteeName) {}
  //   },
  //   templates: {
  //     // TRANSLATE SigningTaskCreatedInviterTemplate
  //     en: '<p> You invited {inviteeName} to sign the document <b>{documentName}</b>. </p> ',
  //     nb: '<p> Du inviterte {inviteeName} til å signere dokumentet <b>{documentName}</b>. </p> ',
  //   },
  //   type: NotificationType.INFORMATION,
  // },
  SigningTaskCompletedInviter: {
    title: {
      // TRANSLATE SigningTaskCompletedInviter-title
      en: 'Signing Task Completed',
      nb: 'Signeringsoppgave fullført',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public inviteeName: string) {}
    },
    templates: {
      // TRANSLATE  SigningTaskCompletedInviter-template
      en: '<p> {inviteeName} has signed the document <b>{documentName}</b> </p> ',
      nb: '<p>{inviteeName} har signert dokumentet <b>{documentName}</b></p>',
    },
    type: NotificationType.INFORMATION,
  },
  SigningTaskCompletedInvitee: {
    title: {
      // TRANSLATE SigningTaskCompletedInvitee-title
      en: 'Signing Task Completed',
      nb: 'Signeringsoppgave fullført',
    },
    code: '',
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningTaskCompletedInvitee-template
      en: '<p>  You have signed the document <b>{documentName}</b> </p> ',
      nb: '<p> Du har signert dokumentet <b>{documentName}</b></p>',
    },
    type: NotificationType.INFORMATION,
  },
  SigningTaskRejectedInviter: {
    title: {
      // TRANSLATE SigningTaskRejectedInviter-title
      en: 'Signing Task Rejected',
      nb: 'Signeringsoppgave avvist',
    },
    code: '',
    data: class {
      constructor(public url: string, public documentName: string, public signerName: string) {}
    },
    templates: {
      // TRANSLATE SigningTaskRejectedInviter-template
      en: '<p> {inviteeName} has rejected to sign the document <b>{documentName}</b> </p> ',
      nb: '<p>{inviteeName} har avvist å signere dokumentet <b>{documentName}</b></p>',
    },
    type: NotificationType.INFORMATION,
  },
  EmailFailedToSend: {
    title: {
      // TRANSLATE EmailFailedToSend-title
      en: 'Email delivery failed',
      nb: 'Levering av e-post mislyktes',
    },
    code: '',
    data: class {
      constructor(public address: string, public topic: string) {}
    },
    templates: {
      // TRANSLATE EmailFailedToSend-template
      en: '<p>Email {topic} has not been delivered to <b>{address}</b></p>',
      nb: '<p>E-post {topic} er ikke levert til <b>{address}</b></p>',
    },
    type: NotificationType.INFORMATION,
  },
  NewsNotification: {
    title: {
      // TRANSLATE NewsNotification-title
      en: 'News update',
      nb: 'Nyhetsoppdatering',
    },
    code: '',
    data: class {
      constructor(public topic, public url: string) {}
    },
    templates: {
      // TRANSLATE NewsNotification-Template
      en: '<p> {topic}. To read more <a href={url} target="_blank">click<a> </p> ',
      nb: '<p>{topic}. For å lese mer <a href= {url} target = "_blank">klikk<a></p></a>',
    },
    type: NotificationType.ACTION_AUTO,
  },
  SigningOrderCreated: {
    title: {
      // TRANSLATE  SigningOrderCreated-title
      en: 'Signing Request Created',
      nb: 'Signeringsordre opprettet',
    },
    code: '',
    data: class {
      constructor(public documentName: string, public url: string) {}
    },
    templates: {
      // TRANSLATE  SigningOrderCreated-template
      en: '<p>A signing request for <b>{documentName}</b> has been created</p> ',
      nb: '<p>Det er opprettet en signeringsordre for <b>{documentName}</b></p> ',
    },
    type: NotificationType.INFORMATION,
  },
  SigningOrderCompletedInviter: {
    title: {
      // TRANSLATE SigningOrderCompletedInviter-title
      en: 'Signing Request Completed',
      nb: 'Signeringsordre fullført',
    },
    code: '',
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningOrderCompletedInviter-template
      en: '<p>A signing order for <b>{documentName}</b> has completed </p> ',
      nb: '<p>En signeringsordre for <b>{documentName}</b> er fullført </p>',
    },
    type: NotificationType.INFORMATION,
  },
  SigningOrderCreateFailed: {
    title: {
      // TRANSLATE SigningOrderCreateFailed-title
      en: 'Signing Request Failed to Create',
      nb: 'Signeringsordre kan ikke opprettes',
    },
    code: '',
    data: class {
      documentName: string;
    },
    templates: {
      // TRANSLATE SigningOrderCreateFailed-template
      en:
        '<p>There was a system problem trying to create a signing request for <b>{documentName}</b></p>',
      nb: '<p>En signeringsordre for <b>{documentName}</b> kan ikke opprettes</p>',
    },
    type: NotificationType.INFORMATION,
  },
  SigningOrderExpiredInviter: {
    title: {
      // TRANSLATE  SigningOrderExpiredInviter-title
      en: 'Signing Request Expired',
      nb: 'Signeringsordre utløpt',
    },
    code: '',
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningOrderExpiredInviter-template
      en: '<p>A signing request for <b>{documentName}</b> expired</p> ',
      nb: '<p>En signeringsordre for <b>{documentName}</b> utløpt</p>',
    },
    type: NotificationType.INFORMATION,
  },
  // TODO
  MeetingInvitation: {
    title: {
      en: 'Meeting Invitation',
      nb: '',
    },
    data: class {
      constructor() {}
    },
    code: '',
    type: NotificationType.INFORMATION,
  },
  MeetingAttendenceReminder: {
    title: {
      en: 'Minutes Reminder',
      nb: '',
    },
    code: '',
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
  },
  MinutesReview: {
    title: {
      en: 'Minutes Review',
      nb: '',
    },
    code: '',
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
  },
  MeetingOrganizerReminderToInvite: {
    title: {
      en: 'Meeting Organizer Reminder To Invite',
      nb: '',
    },
    code: '',
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
  },

  ResponsibleForActionPoint: {
    title: {
      en: 'New action point created',
      nb: 'Nytt aksjonspunkt lagt til',
    },
    code: '',
    data: class {
      title: string;
    },
    templates: {
      en: '<p>An action point that you are responsible for: <b>{title}</b> has been created</p> ',
      nb: '<p>Et aksjonspunkt du er ansvarlig for: <b>{title}</b> har blitt lagt til</p>',
    },
    type: NotificationType.INFORMATION,
  },
};

// Well this is naff but it helps checking.
const keys = Object.keys(notificationCodes);
for (const key of keys) {
  notificationCodes[key].code = key;
}
// This should match the notificationSchema  (lib-mongoose)
// LINK  ../../../../libs/lib-mongoose/src/lib/notification-schema.ts#NOTIFICATION_SCHEMA
// ANCHOR[id=NOTIFICATION_CLASS]
export class Notification {
  id: string;
  due: Date; //  time stamp for when tasks is due  or just the date to order in the list.
  userId: string; // userId of who we are notifying
  code: string; //   Unique code for this Notifiction type -- used for language templates -- decoding data
  data: string; //  Stringified  artbirtary data specific to  code
  status: NotificationStatus; //
  expiresAfter: number; // unix time diff relative to due date
  activiateBefore: number;
  deleted: boolean; //  soft delete flag
  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  organizationId: string; //  organization context
  meetingId: string; // meeting context
  direntId: string; //  for file operations.
  signOrderId: string; // sign orders  mongodb record id
  requestId: String; //  signicat reference for order
  taskId: String; //  reference for a signing task
  createdAt: Date; // mongodb  auto created
  updatedAt: Date;
}

export enum WebPushNotificationType {
  SIGNING_REQUEST = 'signing_request',
  DOCUMENT_SIGNED = 'document_signed',
  MEETING_SCHEDULED = 'meeting_scheduled',
  MEETING_STARTED = 'meeting_started',
  MEETING_ABOUT_TO_START = 'meeting_to_start',
}
