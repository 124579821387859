import { Role } from '@orgbrain/roles';

export class BoardMember {
  id: string;
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  roles: Role[];
  logoUrl: string;
  votingright?: boolean;
  invitationCode?: string;
  sortIndex?: number;
  canBeInvited?: boolean;
  isDeletedFlag: boolean;
  proxyWelcomeText?: string;
  proxyUploadedAttachment?: string;
}
