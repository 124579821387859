export enum IdentityType {
  no_bankid = 'no_bankid',
  sms = 'sms',
  cozone = 'cozone',
  cognito = 'cognito',
}

export const identityTypeLang = {
  [IdentityType.no_bankid]: {
    en: 'Norwegian BankID',
    nb: 'Norwegian BankID',
  },
  [IdentityType.sms]: {
    en: 'SMS',
    nb: 'SMS',
  },
};

export class IdentityRecord {
  identityType: IdentityType;
  identityId: String;
  data: any;
  history: any[];
  allowLogon: boolean;
  // last verification.
  stamp: string | Date;
  createdAt: string | Date;
}
